import logo from './Photo.png';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App">
        <img src={logo} className="Ap" alt="logo" />
        <p className="con" >Site under Constrction!!!</p>
        
        
        
      </header>
    </div>
  );
}

export default App;
